(function () {
    /**
     * ----------------------
     * ---- VUE COMPONENT ---
     * ----------------------
     *
     * - Dependencies:
     *    - crud-favlist component
     *    - premium-modal component
     *
     *    - icon
     *
     *  - Warning:
     *    - Add-to-fav always has a user logged in, thus the premium-modal will
     *      not show the login modal and its dependencies are not required
     */
    Vue.component("add-to-fav", {
        props: [
            "isModalOpen", // boolean
            "spaId", // int
            "i18n",
            "isUserPremium",
        ],
        data: function () {
            return {
                items: [], // favorite lists
                errorMsg: "", // msg to show as error
                isCreateModalOpen: false, // crud-favlist component
                isUserLoggedIn: true, // premium-modal component - add-to-fav always has login check before
                premiumSelectedFeature:
                    GLOBAL_PREMIUM_FEATURES.CUSTOM_TRAIL_LISTS, // premium-modal component - Always custom_trail_lists from the add-to-fav component
                analyticsRef:
                    GLOBAL_PREMIUM_FEATURES.CUSTOM_TRAIL_LISTS.description,
                isPremiumModalOpen: false, // premium-modal component
                isFollowModalOpen: false, // follow-modal component
                userDTO: {}, // follow-modal component
                isLoadingResults: false, // load spinner
            };
        },
        template: `
<div v-show="isModalOpen" class="add-to-fav--mask" ref="addToFavModal">  
  <div class="add-to-fav__wrapper">
    <div class="add-to-fav__content" ref="addToFavModalContent">
      <div class="add-to-fav__header">
        <div class="add-to-fav__title">
          <b>{{i18n.txtAddToList}}</b>
        </div>
        <div class="add-to-fav__close">
          <button type="button" class="add-to-fav__close-button" aria-label="Close" @click="close">
            <img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/search/modal_cross.svg">
          </button>
        </div>
      </div>
      <div v-show="isError" class="add-to-fav__error">
        <p>
          {{ errorMsg }}
        </p>
      </div>
      <div class="add-to-fav__list">
      
        <!-- Loading results -->
        <div v-if="isLoadingResults" class="add-to-fav__loading">
          <div class="spinner__round"></div>
        </div>
        
        <!-- List of results -->
        <div v-else v-for="item in items" v-bind:class="addToFavItemClass(item)" v-on:click="onClickItem(item)">
          <div class="add-to-fav-item__name">
            <div class="add-to-fav-item__name-value">
              {{item.name}}
            </div>
            <div v-if="!item.isPublic" class="add-to-fav-item__private">
              <i class="icon icon-lock-closed color-icon-lock-private"></i>                
            </div>
          </div>
          <div class="add-to-fav-item__count">
            <div class="add-to-fav-item__count-value">
              {{ item.count }} / {{ item.limitTrails }}
            </div>
            <div v-bind:class="bookmarkClass(item)" class="add-to-fav-item__icon">
            </div>
          </div>
        </div>
      </div>
      <div class="add-to-fav__create" v-on:click="onClickCreate">
        <div class="add-to-fav__create-text">
          <img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/01_plus.svg"/>
          {{i18n.txtNewList}}
         </div>
      </div>
    </div>
  </div>
  
  <!--  Modal create list-->
  <crud-favlist
    :isModalOpen="isCreateModalOpen"
    :remaining="remainingLists"
    :i18n="i18n"
    @sync-is-modal-open="syncIsCreateModalOpen"
    @syncCreateList="syncCreateList"
    >
  </crud-favlist>
  
  <!--  Modal Premium -->
  <premium-modal v-if="!isUserPremium"
    :isModalOpen="isPremiumModalOpen"
    :isUserLoggedIn="isUserLoggedIn"
    :selectedFeature="premiumSelectedFeature"
    :i18n="i18n"
    :analyticsRef="analyticsRef"
    @closePremiumModalEvent="closePremiumModal"
  >
  </premium-modal>
  
  <!--  Modal Follow -->
  <follow-modal
    :isModalOpen="isFollowModalOpen"
    :userDTOInput="userDTO"
    :i18n="i18n"
    @closeFollowModalEvent="closeFollowModal"
  >
  </follow-modal>
</div>
`,
        methods: {
            onClickItem: onClickItem,
            onClickCreate: onClickCreate,
            addToFavorite: addToFavorite,
            bookmarkClass: bookmarkClass,
            addToFavItemClass: addToFavItemClass,
            close: close,
            onClickOutside: onClickOutside,
            showError: showError,
            syncIsCreateModalOpen: syncIsCreateModalOpen,
            syncCreateList: syncCreateList,
            closePremiumModal: closePremiumModal,
            closeFollowModal: closeFollowModal,
        },
        computed: {
            isError: isError,
            isMaxFavoriteListReached: isMaxFavoriteListReached,
            remainingLists: remainingLists,
        },
        watch: {
            isModalOpen: isModalOpen,
        },
        //VUE LIFECYCLE
        mounted: mounted,
        beforeDestroy: beforeDestroy,
    });

    /**
     * -----------------
     * ---- CONSTANTS --
     * -----------------
     */
    const ERROR_TTL_MS = 5000; // 5s
    const CLOSE_MODAL_TTL_MS = 250; // 0.25s
    const OPEN_FOLLOW_MODAL_TTL_MS = 350; // 0.35s
    const NO_MORE_PREMIUM_AVAILABLE_TTL_MS = 1500; // 1.5s
    const MAX_LISTS = 27; // max lists per user
    const DEFAULT_LIMIT_TRAILS = 50; // max trails per list
    const FAVORITE_LIST_ID = -1; // og favorite list

    /**
     * -----------------
     * ---- METHODS ----
     * -----------------
     * https://vuejs.org/v2/guide/instance.html#Data-and-Methods
     */
    function onClickItem(item) {
        this.addToFavorite(item);
    }

    function onClickCreate() {
        if (!this.isUserPremium) {
            this.isPremiumModalOpen = true;
        } else if (this.isMaxFavoriteListReached) {
            this.showError(this.i18n.txtReachedLimit);
        } else {
            this.isCreateModalOpen = true;
        }
    }

    function addToFavorite(item) {
        let isFavoriteInAnyListOld = _isFavoriteInAnyList(this.items);

        let url = window.location.origin + "/wikiloc/favourite.do";

        let params = "";
        params += "sid=" + this.spaId;
        params += "&listId=" + item.id;
        params += "&fav=" + !item.isFavorite;

        let items = this.items; // visibility inside callback
        let listId = item.id;
        let isFav = !item.isFavorite;
        _ajaxPost(url, params, (data) => {
            let response = _processAddToFavoriteResponse(data, this);
            let isResponseOK = response && Object.keys(response).length > 0;

            if (isResponseOK) {
                item.isFavorite = response.isFavorite;
                item.count = response.count;
                item.limitTrails = response.limitTrails;

                let isFavoriteInAnyListNew = _isFavoriteInAnyList(items);
                if (!isFavoriteInAnyListNew == isFavoriteInAnyListOld) {
                    _emitIsFavoriteChange(this, isFavoriteInAnyListNew);
                }

                // If we can follow the user, let's open the follow-modal component
                if (response.canFollow && _isListToShowFollowModal(listId)) {
                    this.userDTO = response.userDTO;

                    setTimeout(() => {
                        this.isFollowModalOpen = true;
                    }, OPEN_FOLLOW_MODAL_TTL_MS);
                } else {
                    _emitCloseModal(this);
                }
            }
        });
    }

    function bookmarkClass(item) {
        return item.isFavorite
            ? "add-to-fav-item__bookmark--full"
            : "add-to-fav-item__bookmark--empty";
    }

    function addToFavItemClass(item) {
        return !this.isUserPremium && item.id > 0 // isPremium list
            ? "add-to-fav-item-disabled"
            : "add-to-fav-item";
    }

    function close() {
        _emitCloseModal(this, true, false);
    }

    function onClickOutside(event) {
        // stop click propagation for parent modals
        // without this action, the clickOutside event is triggered in the parent modal, closing it as well.
        event.stopPropagation();

        let component = this.$refs.addToFavModalContent;
        //The click is outside the component
        if (component && !component.contains(event.target)) {
            _emitCloseModal(this);
        }
    }

    function showError(msg) {
        this.errorMsg = msg;

        setTimeout(() => {
            this.errorMsg = "";
        }, ERROR_TTL_MS);
    }

    /**
     * Local event handler with child crud-favlist component. No global eventbus
     * Open the crud-favlist component on Creation mode
     * @param payload with {}
     */
    function syncIsCreateModalOpen() {
        this.isCreateModalOpen = false;
    }

    /**
     * Local event handler with child crud-favlist component. No global eventbus
     * After list creation, add the new list and add the fav
     *
     * @param payload with {id, name, count, isPrivate}
     */
    function syncCreateList(payload) {
        // create favList from sync callback
        let favList = _factoryFavoriteListFromCreateListResponse(payload);

        // push new list
        this.items.push(favList);

        // do to fav in the new list
        this.addToFavorite(favList);

        _emitCloseModal(this);
    }

    function closePremiumModal() {
        this.isPremiumModalOpen = false;
    }

    function closeFollowModal(payload) {
        this.isFollowModalOpen = false;
        if (payload.closeAddToFav) {
            _emitCloseModal(this);
        }
    }

    /**
     * ---- COMPUTED ATTRIBUTES ----
     * https://vuejs.org/v2/guide/computed.html#Computed-Properties
     */
    function isError() {
        return this.errorMsg;
    }

    function remainingLists() {
        return MAX_LISTS - this.items.length;
    }

    function isMaxFavoriteListReached() {
        return this.remainingLists <= 0;
    }

    /**
     * ------------------
     * ---- WATCHERS ----
     * ------------------
     * When Vue reactivity is not enough
     * https://vuejs.org/v2/guide/computed.html#Watchers
     */
    function isModalOpen(newIsModalOpen, oldIsModalOpen) {
        // doubleRaf required to sync events
        _doubleRaf(() => {
            if (newIsModalOpen) {
                _addOnClickOutsideEvent(this);
                _enableDocumentScroll(false);
                _populateFavList(this);
            } else {
                _rmOnClickOutsideEvent(this);
                _enableDocumentScroll(true);
            }
        });
    }

    /**
     * -----------------------
     * ---- VUE LIFECYCLE ----
     * -----------------------
     * https://vuejs.org/v2/guide/instance.html#Lifecycle-Diagram
     */

    /**
     * Called after the instance has been mounted, where el is replaced by the newly created vm.$el.
     * If the root instance is mounted to an in-document element, vm.$el will also be in-document when mounted is called.
     *
     * Note that mounted does not guarantee that all child components have also been mounted.
     * If you want to wait until the entire view has been rendered, you can use vm.$nextTick inside of mounted
     */
    function mounted() {
        //1 - Native event Listeners
    }

    function beforeDestroy() {
        //1 - Native event Listeners
    }

    /**
     * -------------------------
     * ---- PRIVATE METHODS ----
     * -------------------------
     * Remember to use .call() if you need access to Vue scope!
     */

    /**
     * EXPERT TIP.
     * Wait the double of frames than vue.nextTick()
     * Sometimes the computation is much bigger than what nextTick can handle.
     * We need to wait the double of frames: https://github.com/vuejs/vue/issues/9200#issuecomment-468512304
     * @param callback
     */
    function _doubleRaf(callback) {
        requestAnimationFrame(() => {
            requestAnimationFrame(callback);
        });
    }

    function _emitCloseModal(vueInstance, isDelay) {
        if (isDelay) {
            setTimeout(() => {
                vueInstance.$emit("sync-is-modal-open", {
                    trailId: vueInstance.spaId,
                    isOpen: false,
                });
            }, CLOSE_MODAL_TTL_MS);
        } else {
            vueInstance.$emit("sync-is-modal-open", {
                trailId: vueInstance.spaId,
                isOpen: false,
            });
        }
    }

    /**
     * Enable or disable global document scroll.
     *
     * WARN: It breaks the encapsulation of the component, but it is the only way
     * to block the scroll for the modals
     *
     * @param enable: true/false to enable or disable the document scroll
     */
    function _enableDocumentScroll(enable) {
        let value = enable ? "auto" : "hidden";

        document.body.style.overflow = value;
    }

    /**
     * Emit an event to the parent to change the favorite icon (filled VS empty)
     */
    function _emitIsFavoriteChange(vueInstance, isFavorite) {
        vueInstance.$emit("sync-is-favorite", {
            trailId: vueInstance.spaId,
            isFavorite: isFavorite,
        });
    }

    function _addOnClickOutsideEvent(vueInstance) {
        vueInstance.$refs.addToFavModal.addEventListener(
            "click",
            vueInstance.onClickOutside
        );
    }

    function _rmOnClickOutsideEvent(vueInstance) {
        if (vueInstance.$refs.addToFavModal) {
            vueInstance.$refs.addToFavModal.removeEventListener(
                "click",
                vueInstance.onClickOutside
            );
        }
    }

    /**
     * Check if some of the lists contains the current trail as a favorite.
     *    Used to know if the outer "Add to a List" icon is filled or not
     */
    function _isFavoriteInAnyList(items) {
        let i = 0;
        let isFavorite = false;
        while (!isFavorite && i < items.length) {
            isFavorite = items[i].isFavorite;
            i++;
        }

        return isFavorite;
    }

    /**
     * Main function to populate favorite lists items
     */
    function _populateFavList(vueInstance) {
        _fetchData(vueInstance.spaId, vueInstance);
    }

    /**
     * Perform an ajax call to retrieve the favorite list
     */
    function _fetchData(spaId, vueInstance) {
        vueInstance.isLoadingResults = true;
        var url = window.location.origin + "/wikiloc/get-favorite-list.do";
        var params = "spaId=" + spaId;

        _ajaxPost(url, params, (data) => {
            vueInstance.items = _processGetListsResponse(data);

            let isFavorite = _isFavoriteInAnyList(vueInstance.items);
            _emitIsFavoriteChange(vueInstance, isFavorite);
            vueInstance.isLoadingResults = false;
        });
    }

    function _processGetListsResponse(result) {
        favoriteLists = [];

        for (var i = 0; i < result.list.length; i++) {
            var item = result.list[i];

            var favList = _factoryFavoriteList(item);
            favoriteLists.push(favList);
        }

        _sortLists(favoriteLists);

        return favoriteLists;
    }

    /**
     * Ensure correct sort. favorites > wanttogo > the rest by id asc
     */
    function _sortLists(favoriteList) {
        // sort by id
        favoriteList.sort((a, b) => (a.id > b.id ? 1 : -1));

        // set favorites > wanttogo > the rest by id asc
        let wantToGo = favoriteList[0]; // -2
        let favorites = favoriteList[1]; // -1
        favoriteList[0] = favorites;
        favoriteList[1] = wantToGo;
    }

    function _isListToShowFollowModal(listId) {
        return FAVORITE_LIST_ID == listId;
    }

    function _processAddToFavoriteResponse(result, vueInstance) {
        var addToFavoriteResponse = {};

        if (result.res == "OK") {
            addToFavoriteResponse = _factoryAddToFavoriteResponse(result);
        } else {
            vueInstance.showError(result.err);
            if (result.errCode === "NO-PREMIUM") {
                setTimeout(() => {
                    vueInstance.isPremiumModalOpen = true;
                }, NO_MORE_PREMIUM_AVAILABLE_TTL_MS);
            }
        }

        return addToFavoriteResponse;
    }

    /**
     *    Post Async ajax call
     */
    function _ajaxPost(url, formData, callback) {
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
                try {
                    var data = JSON.parse(xmlhttp.responseText);
                } catch (err) {
                    console.error(err.message + " in " + xmlhttp.responseText);
                    return;
                }
                callback(data);
            }
        };

        xmlhttp.open("POST", url, true);
        xmlhttp.setRequestHeader(
            "Content-Type",
            "application/x-www-form-urlencoded; charset=UTF-8"
        );
        xmlhttp.send(formData);
    }

    function _factoryFavoriteList(item) {
        return new FavoriteList(
            item.id,
            item.name,
            item.isPublic,
            item.favorite,
            item.count,
            item.limitTrails
        );
    }

    function _factoryFavoriteListFromCreateListResponse(response) {
        return new FavoriteList(
            response.id,
            response.name,
            !response.isPrivate
        );
    }

    function _factoryAddToFavoriteResponse(response) {
        return new AddToFavoriteResponse(
            response.res,
            response.a,
            response.canfollow,
            response.userDTO,
            response.count,
            response.limitTrails
        );
    }

    /**
     * --------------
     * ---- DTOs ----
     * --------------
     *
     */
    function FavoriteList(id, name, isPublic, favorite, count, limitTrails) {
        this.id = id;
        this.name = name;
        this.isPublic = isPublic;
        this.isFavorite = favorite ? favorite : false;
        this.count = count ? count : 0;
        this.limitTrails = limitTrails ? limitTrails : DEFAULT_LIMIT_TRAILS;
    }

    function AddToFavoriteResponse(
        success,
        isFavorite,
        canFollow,
        userDTO,
        count,
        limitTrails
    ) {
        this.success = success;
        this.isFavorite = isFavorite;
        this.canFollow = canFollow;
        this.userDTO = userDTO ? userDTO : null;
        this.count = count;
        this.limitTrails = limitTrails;
    }
})();
